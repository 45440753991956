<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <MyRight />
    <div>
      <div class="img-box">
        <img src="./../static/img-box.png" alt="">
        <div class="img-text">
          <div class="img-text-title1">保障薪权益</div>
          <div class="img-text-title2">为农民工工资保驾护航！</div>
          <!-- <div class="img-text-title3" @click="goToApply">立即办理</div> -->
        </div>
      </div>
      <div class="background">
        <div class="w1300 t30">
          <div class="center">
            <div class="img-title2-2">
              <div style="margin-bottom: 5px;margin-top: 30px;">项目申报办理流程</div>
              <div style="font-size: 20px;color: rgb(102, 102, 102);">全流程在线办理</div>
              <img src="./../static/title.png" alt="" class="img-title1-img">
            </div>
          </div>
          <div class="center speaceBetween t35">
            <div class="garden-box" v-for="(item,index) in gardenList" :key="item.id">
              <div class="garden-img">
                <img :src="item.imgUrl" alt="" style="margin-left: 10px;">
              </div>
              <div style="width: 100%;position: relative;display: flex;justify-content: center;">
                <div class="garden-dot"></div>
                <div class="garden-line"></div>
              </div>
              <div class="garden-num">step.{{1+index}}</div>
              <div class="garden-title">{{item.title}}</div>
            </div>
          </div>
        </div>
        <div class="w1300 t100">
          <div class="center">
            <div class="img-title1">
              <div>平台价值</div>
              <img src="./../static/title1.png" alt="" class="img-title1-img">
            </div>
          </div>
          <div class="center speaceBetween t13">
            <div class="PlatformValue" v-for="item in PlatformValueList" :key="item.id">
              <div class="PlatformValue-title">
                {{item.title}}
                <img src="./../static/sanjiao.png" alt="" class="sanjiao">
              </div>
              <div class="PlatformValue-content">{{item.content}}</div>
            </div>
          </div>
        </div>
        <div class="w1300 t100">
          <div class="center">
            <div class="img-title2">
              <div>新闻动态</div>
              <img src="./../static/title2.png" alt="" class="img-title2-img">
              <div class="img-title2-more" @click="toNews">查看更多 ></div>
            </div>
          </div>
          <div class="center speaceBetween t13">
            <div class="w640"><img src="./../static/header.png" alt=""></div>
            <div class="w640">
              <div v-for="(item,index) in NewsList" :key="item.id" class="bagc" @click="toNewsDetail(item.id)">
                <div class="NewsUpdates-img-box" v-if="index==0">
                  <div style="width: 220px;height: 160px;"><img :src="getDynamicImageUrl(item.annex)" alt=""></div>
                  <div style="display:flex;flex-direction: column;justify-content: space-between;">
                    <div class="NewsUpdates-title-x">
                      {{item.title}}
                    </div>
                    <div class="NewsUpdates-time">{{item.updateTime}}</div>
                  </div>
                </div>
                <div class="NewsUpdates" v-if="index!=0">
                  <div class="NewsUpdates-title">{{item.title}}</div>
                  <div class="NewsUpdates-time">{{item.updateTime}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
    <el-dialog v-model="dialogVisible" title="Tips" width="1139" @close='close'>
      <div style="border-radius: 5px;background: #fff;display: flex;justify-content: space-between;">
        <div style="width:736px;"><img src="./../static/login-img.png" alt="" style="object-fit:cover;width: 100%;height: 100%;"></div>
        <div class="login-form">
          <div class="login-ico" @click="CloseDialog">
            <el-icon>
              <Close style="font-size:30px;" />
            </el-icon>
          </div>
          <div class="login-title">欢迎登录</div>
          <div class="login-method">
            <div class="flex" v-for="item in SignInMethod" :key="item.id">
              <div style="cursor: pointer;" :class="{'active':item.id==activeId}" @click="activeIdMed(item.id)">{{item.title}}</div>
              <div v-if="item.id<3" style="margin: 0 15px;">
                <el-icon>
                  <Switch />
                </el-icon>
              </div>
            </div>
          </div>
          <div>
            <UserLogin v-if="activeId==1" />
            <PhoneLogin v-if="activeId==2" />
            <CaLogin v-if="activeId==3" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import MyRight from "@/components/MyRight.vue";
import UserLogin from "@/components/login/UserLogin.vue";
import PhoneLogin from "@/components/login/PhoneLogin.vue";
import CaLogin from "@/components/login/CaLogin.vue";
import { useRouter } from "vue-router";
import { list } from "@/api/index.js"
import { ref, onMounted } from "vue";
let router = useRouter()
let dialogVisible = ref(false)
let activeId = ref(1)
let SignInMethod = ref([
  { id: 1, title: '账号登录' },
  { id: 2, title: '手机号登录' },
  { id: 3, title: 'CA登录' }
])
let gardenList = ref([
  // { id: 1, imgUrl: require('./../static/step1.png'), title: '项目信息申报', num: 'step.1' },
  // { id: 2, imgUrl: require('./../static/step2.png'), title: '项目审核', num: 'step.2' },
  // { id: 3, imgUrl: require('./../static/step3.png'), title: '保证金核定', num: 'step.2' },
  // { id: 4, imgUrl: require('./../static/step2.png'), title: '机构审核', num: 'step.4' },
  // { id: 5, imgUrl: require('./../static/step5.png'), title: '三制落实', num: 'step.3' },
  // { id: 6, imgUrl: require('./../static/step6.png'), title: '季度申报', num: 'step.4' },
  // { id: 7, imgUrl: require('./../static/step2.png'), title: '工资监管', num: 'step.5' },
  { id: 1, imgUrl: require('./../static/step1.png'), title: '项目申请',num: 'step.1' },
  { id: 2, imgUrl: require('./../static/step7.png'), title: '项目审核',num: 'step.2' },
  { id: 3, imgUrl: require('./../static/step3.png'), title: '保证申请', num: 'step.3' },
  { id: 4, imgUrl: require('./../static/step7.png'), title: '机构审核', num: 'step.4'},
  { id: 5, imgUrl: require('./../static/step5.png'), title: '费用缴纳',num: 'step.5' },
  { id: 6, imgUrl: require('./../static/step6.png'), title: '出纳',num: 'step.6' },
  { id: 7, imgUrl: require('./../static/step7.png'), title: '人社审核',num: 'step.7' },
])
let PlatformValueList = ref([
  { id: 1, title: '欠薪维权', content: '农民工工资履约保证金保函数据可追溯，助力欠薪 维权，转变维权主体，促进社会公平和谐' },
  { id: 2, title: '透明监管', content: '平台数据可追溯，政府部门与社会公众均可监督，增强了工资支付的透明度，促进社会公平正义' },
  { id: 3, title: '即时赔付', content: '一旦发生欠薪情况，见索即付机制确保农民工能够迅速获得赔偿，缓解社会矛盾' },
  { id: 4, title: '赋能企业', content: '企业可以通过保函形式代替现金保证金，大大减轻资金占用压力，优化现金流，促进企业健康发展' },
])
let NewsList = ref()
let toNews = () => {
  router.push('/newsList')
}
let toNewsDetail = (id) => {
  router.push({ path: '/newsListDetail', query: { id: id } })
}
let NewsArr = async () => {
  await list({ current: 1, size: 5, type: 1 }).then(res => {
    res.data.records.forEach((item) => {
      item.annex = JSON.parse(item.annex)
      if (Array.isArray(item.annex) && item.annex.length > 0) {
        let url = item.annex[0];
        let path = url.substring(url.lastIndexOf('/upload'));
        item.annex = path;
      }
    })
    NewsList.value = res.data.records
  })
}
let getDynamicImageUrl = (url) => {
  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const baseUrl = `${parts[0]}//${parts[2]}`;
  console.log(`${baseUrl}` + `${url}`, 'url');
  return `${baseUrl}` + '/' + `${url}`;
}
let close = () => {
  dialogVisible.value = false
  localStorage.removeItem('Dialog')
  activeId.value = 3
}
let CloseDialog = () => {
  dialogVisible.value = false
  localStorage.removeItem('Dialog')
  activeId.value = 3
}
let activeIdMed = (id) => {
  activeId.value = id
}
let goToApply = () => {
  const tokenUser = localStorage.getItem('tokenuser');
  if (tokenUser && JSON.stringify(tokenUser) !== 'null') {
    router.push('/deposit');
  } else {
    dialogVisible.value = true;
    activeId.value = 1;
  }
}
onMounted(async () => {
  await NewsArr()
})
</script>
<style scoped>
::v-deep .el-dialog {
  padding: 0;
}
::v-deep .el-dialog__header {
  display: none;
}
.login-form {
  width: 403px;
  height: 100%;
  padding: 0 52px;
  box-sizing: border-box;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.login-ico {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.login-title {
  font-size: 30px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  margin-top: 75px;
}
.login-method {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  display: flex;
  margin-bottom: 44px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  color: rgba(31, 112, 247, 1);
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-box {
  width: 100%;
  height: 380px;
  position: relative;
}
.img-text {
  width: 1300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.img-text-title1 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 35.16px;
  color: rgba(51, 51, 51, 1);
  margin: 110px 0 0 100px;
}
.img-text-title2 {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 49.22px;
  color: rgba(0, 82, 217, 1);
  margin: 10px 0 0 100px;
}
.img-text-title3 {
  width: 140px;
  height: 48.75px;
  opacity: 1;
  background: rgba(212, 227, 252, 1);
  border: 2px solid rgba(59, 133, 244, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 30px 0 0 100px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.44px;
  color: rgba(59, 133, 244, 1);
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.w640 {
  width: 640px;
  height: 400px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(233, 237, 242, 1);
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.12);
}
.t30 {
  margin-top: 30px;
}
.t35 {
  margin-top: 35px;
}
.t100 {
  margin-top: 100px;
}
.t13 {
  margin-top: 13px;
}
.background {
  background: rgb(253, 253, 253);
}
.img-title {
  width: 301px;
  height: 107px;
}
.img-title2-2 {
  width: 300px;
  height: 87px;
  font-size: 36px;
  font-weight: 500;
  color: rgba(0, 82, 217, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-title1 {
  width: 198px;
  height: 87px;
  font-size: 36px;
  font-weight: 500;
  color: rgba(0, 82, 217, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-title2 {
  width: 179px;
  height: 87px;
  font-size: 36px;
  font-weight: 500;
  color: rgba(0, 82, 217, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-title2-more {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 82, 217, 1);
  margin-top: 5px;
  cursor: pointer;
  z-index: 9999;
}
.img-title2-img {
  position: absolute;
  left: 0px;
  top: 5px;
}
.img-title1-img {
  position: absolute;
  left: 0px;
  top: 15px;
}
.center {
  display: flex;
  justify-content: center;
}
.speaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.garden-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(
    132.44deg,
    rgba(248, 251, 255, 1) 0%,
    rgba(248, 251, 255, 1) 100%
  );
  box-shadow: 0px 0px 8px 2px rgba(0, 82, 217, 0.1),
    0px 1px 0px rgba(17, 17, 26, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.garden-dot {
  width: 20px;
  height: 20px;
  background: rgba(42, 123, 234, 1);
  border-radius: 50%;
  margin-top: 40px;
}
.garden-line {
  position: absolute;
  bottom: 9px;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    #2a7bea 0%,
    #2a7bea 50%,
    transparent 50%
  );
  background-size: 15px 1px;
  background-repeat: repeat-x;
}
.garden-num {
  font-size: 16px;
  font-weight: 500;
  line-height: 23.17px;
  color: rgba(31, 112, 247, 1);
  margin-top: 20px;
}
.garden-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26.06px;
  color: rgba(69, 87, 124, 1);
  margin-top: 5px;
}
.garden-box {
  width: calc(100% / 5);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
}
.PlatformValue {
  width: 280px;
  height: 220px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 1.95px 1.95px 2.6px rgba(0, 0, 0, 0.15);
}
.sanjiao {
  position: absolute;
  top: 0;
  left: 0;
  width: 173.5px;
  height: 80.5px;
}
.PlatformValue-title {
  width: 280px;
  height: 80px;
  background: rgba(60, 158, 246, 1);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 80px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlatformValue-content {
  width: 280px;
  height: 140px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 233, 248, 1);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(51, 51, 51, 1);
  padding: 31px 31px 43px 31px;
  box-sizing: border-box;
}
.NewsUpdates {
  height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px 13px 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.bagc:nth-child(2n) {
  background: rgb(245, 247, 250);
}
.NewsUpdates-title {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.NewsUpdates-time {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  align-self: flex-end;
}
.NewsUpdates-title-x {
  width: 360px;
  height: 125px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(0, 0, 0, 1);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
}
.NewsUpdates-img-box {
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  cursor: pointer;
}
</style>